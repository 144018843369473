import React, { Suspense } from "react";
export function Loader() {
  return (
    <>
      <div className="page-loading text-center">
        <div className="content">
          <img
            src="dist/img/logo-zenplus.png"
            className="flipimg"
            width={"50px"}
          ></img>{" "}
          <h6 className="mb-0">Please wait...</h6>
        </div>
      </div>
    </>
  );
}

export function LogoutLoader() {
  return (
    <>
      <div className="page-loading text-center">
        <div className="content">
          <img
            src="dist/img/logo-zenplus.png"
            className="flipimg"
            width={"50px"}
          ></img>{" "}
          <h6 className="mb-0">Loggging out...</h6>
        </div>
      </div>
    </>
  );
}
