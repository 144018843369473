import React, { Component, Fragment } from "react";
import { axiosPost } from "../apis/useAxios";
import axios from "axios";
import {
  ClearSessionItems,
  LocalStorage,
  getCurrentLocation,
} from "../helper/appsession";
import { Icon } from "@iconify/react";
import { LoginAssetType } from "../helper/fixcode";
import { LogoutLoader } from "./Shared/PageLoader";

export class SideBarComponent extends Component {
  static displayName = SideBarComponent.name;

  constructor(props) {
    super(props);
    this.state = {
      userFeatureSetup: JSON.parse(LocalStorage.UserFeatureSetup),
      roleSetup: null,
      isLoading: false,
    };
  }

  async componentDidMount() {
    //console.log("UserFeatureSetup: " + LocalStorage.UserFeatureSetup);
    let menuHtmlItem = this.getMenuHtml(this.state.userFeatureSetup);
    this.setState({
      menuHtml: menuHtmlItem,
    });
  }

  getMenuHtml(menuFeature) {
    let menuHtmlItem = [];
    menuFeature = JSON.parse(JSON.stringify(menuFeature));
    menuFeature?.forEach((menu) => {
      if (menu.subFeatureId === 0) {
        let childMenu = menuFeature.filter(
          (el) => el.subFeatureId === menu.featureId
        );
        const menuName = menu.displayName; //getVehicleLabelByIndustry(menu.menuName);        
        if (childMenu.length > 0) {
          menuHtmlItem.push(
            <li key={menu.featureId} className="nav-item">
              <a href={menu.requestPath === "" ? "/" : menu.requestPath} className="nav-link">
                <Icon icon={menu.iconName} className="nav-icon" />
                <p>
                  {menuName}
                  <i className="right fas fa-angle-left"></i>
                </p>
              </a>
              <ul key={menu.featureId} className="nav nav-treeview">
                {this.subMenuHtml(childMenu)}
              </ul>
            </li>
          );
        }
        else if (menuName === "Tracking") {
          menuHtmlItem.push(
            <li key={menu.featureId} className="nav-item">
              <a onClick={() => { this.redirectToTracking(); }} className="nav-link" style={{ cursor: "pointer" }}>
                <Icon icon={menu.iconName} className="nav-icon" />
                <p>{menu.displayName}</p>
              </a>
            </li>
          );
        }
        else if (menuName === "Ticket") {
          menuHtmlItem.push(
            <li key={menu.featureId} className="nav-item">
              <a href="https://ticketing.utwiz.com/" target="_new" className="nav-link" style={{ cursor: "pointer" }}>
                <Icon icon={menu.iconName} className="nav-icon" />
                <p>{menu.displayName}</p>
              </a>
            </li>
          );
        }
        else {
          menuHtmlItem.push(
            <li key={menu.featureId} className="nav-item">
              <a href={menu.requestPath} className="nav-link">
                <Icon icon={menu.iconName} className="nav-icon" />
                <p>{menu.displayName}</p>
              </a>
            </li>
          );
        }
      }
    });
    return menuHtmlItem;
  }

  subMenuHtml = (menuFeature) =>
    menuFeature.map((subchild) => (
      <li key={subchild.featureId} className="nav-item">
        <a href={subchild.requestPath} className="nav-link">
          <Icon icon={subchild.iconName} className="nav-icon" />
          <p>{subchild.displayName}</p>
        </a>
      </li>
    ));

  handleOnLogout = async (e) => {
    e.preventDefault();
    try {
      this.setState({
        isLoading: true
      })
      let userId = LocalStorage.UserId;
      const { lat, lng } = await getCurrentLocation();
      let url = `/authenticate/user/${userId}/logout`;
      const response = await axiosPost(url, {
        SessionId: LocalStorage.SessionId,
        AssetTypeId: LoginAssetType.Web,
        Latitude: lat,
        Longitude: lng,
      });
      if (response.success) {
        console.log(response.message);
      }
    } catch (error) {
      console.log(error);
    }
    ClearSessionItems();
    window.location.href = "/";
  };

  redirectToTracking = async () => {
    this.setState({ loadPanelVisible: true });
    try {
      const redirectdata = await axios
        .request(process.env.REACT_APP_CUSTOMER_API_URL + `RedirectUser/Insert/${LocalStorage.UserName}`)
        .then((res) => res.data)
        .catch((err) => {

        })
        .finally(() => {

        });

      this.setState({ loadPanelVisible: false });
      if (redirectdata.payload != null) {
        window.open(process.env.REACT_APP_CUSTOMER_SITE_URL + "?userguid=" + redirectdata.payload.token + "&source=ops", '_blank');
      }
      else if (redirectdata.token != null) {
        window.open(process.env.REACT_APP_CUSTOMER_SITE_URL + "?userguid=" + redirectdata.token + "&source=ops", '_blank');
      }
    } catch (err) {
      console.log("Error in " + this.displayName + ".handleUserSubmit function", err);
      this.setState({ loadPanelVisible: false });
    }
  }

  render() {
    return (
      <Fragment>
        <aside className="main-sidebar sidebar-dark-primary elevation-4">
          <a href="/" className="brand-link">
            <img
              src="dist/img/zenplus-icon.png"
              alt="AdminLTE Logo"
              className="brand-image img-circle elevation-3"
              style={{ opacity: ".8" }}
            />
            <span className="brand-text font-weight-light">
              <img
                src="dist/img/zenplus-fleet-maangement-logo-light.svg"
                alt="AdminLTE Logo"
                className="brand-image1"
              />
            </span>
          </a>
          <div className="sidebar">
            <nav className="mt-2">
              <ul
                className="nav nav-pills nav-sidebar flex-column"
                data-widget="treeview"
                role="menu"
                data-accordion="false"
              >
                {/* <a onClick={(e) => { this.redirectToTracking(); }} className="nav-link"><p>Tracking</p></a> */}
                {this.state.menuHtml}
                {/* {LocalStorage.IsAdminUserLoggedIn === true && (
                  <Fragment>
                    <li className="nav-item">
                      <a href="/home" className="nav-link">
                        <Icon icon="uil:dashboard" className="nav-icon" />
                        <p>Dashboard</p>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="/leads" className="nav-link">
                        <Icon icon="uil:plus-circle" className="nav-icon" />
                        <p>Leads</p>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="/driveronboarding" className="nav-link">
                        <Icon
                          icon="healthicons:truck-driver"
                          className="nav-icon "
                        />
                        <p>Driver Partner Onboarding</p>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="#" className="nav-link">
                        <Icon
                          icon="carbon:document-requirements"
                          className="nav-icon"
                        />
                        <p>
                          Champion Settlement
                          <i className="right fas fa-angle-left"></i>
                        </p>
                      </a>
                      <ul className="nav nav-treeview">
                        <li className="nav-item">
                          <a href="/settlementdriverwise" className="nav-link">
                            <Icon icon="codicon:circle" className="nav-icon" />
                            <p>Settlement Driver Wise</p>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a href="/lastweeksettlement" className="nav-link">
                            <Icon icon="codicon:circle" className="nav-icon" />
                            <p>Last Week Settlement</p>
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="nav-item">
                      <a href="#" className="nav-link">
                        <Icon icon="iconamoon:discount" className="nav-icon" />
                        <p>
                          Scheme
                          <i className="right fas fa-angle-left"></i>
                        </p>
                      </a>
                      <ul className="nav nav-treeview">
                        <li className="nav-item">
                          <a href="/schememaster" className="nav-link">
                            <Icon icon="codicon:circle" className="nav-icon" />
                            <p>Scheme</p>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a href="/schemeaddon" className="nav-link">
                            <Icon icon="codicon:circle" className="nav-icon" />
                            <p>Addon</p>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a href="/incentivescheme" className="nav-link">
                            <Icon icon="codicon:circle" className="nav-icon" />
                            <p>Incentive Scheme</p>
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="nav-item">
                      <a href="/document-qc" className="nav-link">
                        <Icon
                          icon="basil:document-outline"
                          className="nav-icon"
                        />
                        <p>Document QC</p>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="/refund" className="nav-link">
                        <Icon icon="iconamoon:discount" className="nav-icon" />
                        <p>Refund</p>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="/vehicles" className="nav-link">
                        <Icon icon="mdi:car-outline" className="nav-icon" />
                        <p>Vehicles</p>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="#" className="nav-link">
                        <Icon icon="bx:cog" className="nav-icon" />
                        <p>
                          Settings
                          <i className="right fas fa-angle-left"></i>
                        </p>
                      </a>
                      <ul className="nav nav-treeview">
                        <li className="nav-item">
                          <a href="/user" className="nav-link">
                            <Icon icon="codicon:circle" className="nav-icon" />
                            <p>User</p>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a href="/role" className="nav-link">
                            <Icon icon="codicon:circle" className="nav-icon" />
                            <p>User Role</p>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a href="/usertype" className="nav-link">
                            <Icon icon="codicon:circle" className="nav-icon" />
                            <p>User Type</p>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a href="/feature" className="nav-link">
                            <Icon icon="codicon:circle" className="nav-icon" />
                            <p>Feature</p>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a href="/branch-area" className="nav-link">
                            <Icon icon="codicon:circle" className="nav-icon" />
                            <p>Branch Area</p>
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="nav-item">
                      <a href="#" className="nav-link">
                        <Icon
                          icon="healthicons:truck-driver"
                          className="nav-icon "
                        />
                        <p>
                          Change Request
                          <i className="right fas fa-angle-left"></i>
                        </p>
                      </a>
                      <ul className="nav nav-treeview">
                        <li className="nav-item">
                          <a href="/schemechangerequest" className="nav-link">
                            <Icon icon="codicon:circle" className="nav-icon" />
                            <p>Scheme Change Request</p>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a href="/markcarforrecovery" className="nav-link">
                            <Icon icon="codicon:circle" className="nav-icon" />
                            <p>Mark Car for Recovery</p>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a href="/carimmobilization" className="nav-link">
                            <Icon icon="codicon:circle" className="nav-icon" />
                            <p>Car Immobilization</p>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a href="/waiver" className="nav-link">
                            <Icon icon="codicon:circle" className="nav-icon" />
                            <p>Waiver</p>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a href="/driverpark" className="nav-link">
                            <Icon icon="codicon:circle" className="nav-icon" />
                            <p>Driver Park</p>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a href="/driverterminate" className="nav-link">
                            <Icon icon="codicon:circle" className="nav-icon" />
                            <p>Driver Terminate</p>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a href="/driverblacklist" className="nav-link">
                            <Icon icon="codicon:circle" className="nav-icon" />
                            <p>Driver BlackList</p>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a href="#" className="nav-link">
                            <Icon icon="codicon:circle" className="nav-icon" />
                            <p>Team Incentive</p>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a href="/targetmapping" className="nav-link">
                            <Icon icon="codicon:circle" className="nav-icon" />
                            <p>Target Mapping</p>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a href="/competitor-scheme" className="nav-link">
                            <Icon icon="codicon:circle" className="nav-icon" />
                            <p>Competitor Scheme</p>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a href="/vehiclerelease" className="nav-link">
                            <Icon icon="codicon:circle" className="nav-icon" />
                            <p>Vehicle Release</p>
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="nav-item">
                      <a href="/leadcost" className="nav-link">
                        <Icon icon="solar:dollar-bold" className="nav-icon " />
                        <p>Lead Cost </p>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="/gateregister" className="nav-link">
                        <Icon icon="solar:dollar-bold" className="nav-icon " />
                        <p>Gate Register </p>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="#" className="nav-link">
                        <Icon
                          icon="healthicons:truck-driver"
                          className="nav-icon "
                        />
                        <p>
                          Driver Status Change
                          <i className="right fas fa-angle-left"></i>
                        </p>
                      </a>
                      <ul className="nav nav-treeview">
                        <li className="nav-item">
                          <a href="/driverpartnerpark" className="nav-link">
                            <Icon icon="codicon:circle" className="nav-icon" />
                            <p>Driver Partner Park</p>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            href="/driverpartnerterminate"
                            className="nav-link"
                          >
                            <Icon icon="codicon:circle" className="nav-icon" />
                            <p>Driver Partner Terminate</p>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            href="/driverpartnerblocklist"
                            className="nav-link"
                          >
                            <Icon icon="codicon:circle" className="nav-icon" />
                            <p>Driver Partner Blocklist</p>
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="nav-item">
                      <a href="/fieldexecutive" className="nav-link">
                        <Icon icon="mdi:user-outline" className="nav-icon" />
                        <p>Field Executives</p>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="/champion" className="nav-link">
                        <Icon icon="mdi:user-outline" className="nav-icon" />
                        <p>Champion</p>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="/vehicleallocation" className="nav-link">
                        <Icon icon="mdi:car-outline" className="nav-icon" />
                        <p>Vehicle Allocation</p>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="/incentive" className="nav-link">
                        <Icon icon="gridicons:money" className="nav-icon" />
                        <p>Incentives</p>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="/ticket" className="nav-link">
                        <Icon
                          icon="pajamas:issue-type-ticket"
                          className="nav-icon"
                        />
                        <p>Ticket</p>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="/car-onboarding" className="nav-link">
                        <Icon icon="mdi:car-outline" className="nav-icon" />
                        <p>Car Onboarding</p>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="/vehicleqc" className="nav-link">
                        <Icon icon="bx:cog" className="nav-icon" />
                        <p>Car Inventory</p>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="/vendor" className="nav-link">
                        <Icon
                          icon="healthicons:truck-driver"
                          className="nav-icon "
                        />
                        <p>Vendor</p>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="#" className="nav-link">
                        <Icon
                          icon="healthicons:i-training-class"
                          className="nav-icon "
                        />
                        <p>Training</p>
                        <i className="right fas fa-angle-left"></i>
                      </a>
                      <ul className="nav nav-treeview">
                        <li className="nav-item">
                          <a href="/trainingslots" className="nav-link">
                            <Icon icon="codicon:circle" className="nav-icon" />
                            <p>Training Slots</p>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a href="/trainingschedule" className="nav-link">
                            <Icon icon="codicon:circle" className="nav-icon" />
                            <p>Training Schedule</p>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a href="/training-location" className="nav-link">
                            <Icon icon="codicon:circle" className="nav-icon" />
                            <p>Training Location</p>
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="nav-item">
                      <a href="#" className="nav-link">
                        <Icon icon="mdi:car-cog" className="nav-icon" />
                        <p>
                          Service
                          <i className="right fas fa-angle-left"></i>
                        </p>
                      </a>
                      <ul className="nav nav-treeview">
                        <li className="nav-item">
                          <a href="/ratecard" className="nav-link">
                            <Icon icon="codicon:circle" className="nav-icon" />
                            <p>Rate Card</p>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a href="/estimate" className="nav-link">
                            <Icon icon="codicon:circle" className="nav-icon" />
                            <p>Estimate</p>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a href="/workapproval" className="nav-link">
                            <Icon icon="codicon:circle" className="nav-icon" />
                            <p>Work Approval</p>
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="nav-item">
                      <a href="#" className="nav-link">
                        <Icon icon="nimbus:file-alt" className="nav-icon" />
                        <p>
                          Legal
                          <i className="right fas fa-angle-left"></i>
                        </p>
                      </a>
                      <ul className="nav nav-treeview">
                        <li className="nav-item">
                          <a href="/stamppapersummery" className="nav-link">
                            <Icon icon="codicon:circle" className="nav-icon" />
                            <p>Stamp Paper Summary</p>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a href="/stamppaperinventory" className="nav-link">
                            <Icon icon="codicon:circle" className="nav-icon" />
                            <p>Stamp Paper Inventory</p>
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="nav-item">
                      <a href="/vehicleprofitloss" className="nav-link">
                        <Icon icon="mdi:car-outline" className="nav-icon" />
                        <p>Vehicle Profit & Loss</p>
                      </a>
                    </li>
                  </Fragment>
                )}
                {LocalStorage.UserTypeId === UserType.QCAdmin && (
                  <Fragment>
                    <li className="nav-item">
                      <a href="/document-qc" className="nav-link">
                        <Icon
                          icon="basil:document-outline"
                          className="nav-icon"
                        />
                        <p>Document QC</p>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="/vehicleqc" className="nav-link">
                        <Icon icon="bx:cog" className="nav-icon" />
                        <p>Car Inventory</p>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="/vehicleinspectionqc" className="nav-link">
                        <Icon icon="bx:cog" className="nav-icon" />
                        <p>Car Inspection</p>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="/vendor" className="nav-link">
                        <Icon
                          icon="healthicons:truck-driver"
                          className="nav-icon "
                        />
                        <p>Vendor</p>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="/champion" className="nav-link">
                        <Icon icon="mdi:user-outline" className="nav-icon" />
                        <p>Champion</p>
                      </a>
                    </li>
                  </Fragment>
                )}
                {LocalStorage.UserTypeId === UserType.SourcingManagerAdmin && (
                  <Fragment>
                    <li className="nav-item">
                      <a href="/home" className="nav-link">
                        <Icon icon="uil:dashboard" className="nav-icon" />
                        <p>Dashboard</p>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="/leads" className="nav-link">
                        <Icon icon="uil:plus-circle" className="nav-icon" />
                        <p>Leads</p>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="#" className="nav-link">
                        <Icon
                          icon="carbon:document-requirements"
                          className="nav-icon"
                        />
                        <p>
                          Champion Settlement
                          <i className="right fas fa-angle-left"></i>
                        </p>
                      </a>
                      <ul className="nav nav-treeview">
                        <li className="nav-item">
                          <a href="/settlementdriverwise" className="nav-link">
                            <Icon icon="codicon:circle" className="nav-icon" />
                            <p>Settlement Driver Wise</p>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a href="/lastweeksettlement" className="nav-link">
                            <Icon icon="codicon:circle" className="nav-icon" />
                            <p>Last Week Settlement</p>
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="nav-item">
                      <a href="/leadcost" className="nav-link">
                        <Icon icon="solar:dollar-bold" className="nav-icon " />
                        <p>Lead Cost </p>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="#" className="nav-link">
                        <Icon
                          icon="healthicons:truck-driver"
                          className="nav-icon "
                        />
                        <p>
                          Change Request
                          <i className="right fas fa-angle-left"></i>
                        </p>
                      </a>
                      <ul className="nav nav-treeview">
                        <li className="nav-item">
                          <a href="/incentivescheme" className="nav-link">
                            <Icon icon="codicon:circle" className="nav-icon" />
                            <p>Incentive Scheme</p>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a href="/targetmapping" className="nav-link">
                            <Icon icon="codicon:circle" className="nav-icon" />
                            <p>Target Mapping</p>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a href="/competitor-scheme" className="nav-link">
                            <Icon icon="codicon:circle" className="nav-icon" />
                            <p>Competitor Scheme</p>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a href="/refund" className="nav-link">
                            <Icon icon="codicon:circle" className="nav-icon" />
                            <p>Refund Request</p>
                          </a>
                        </li>
                      </ul>
                    </li>
                  </Fragment>
                )}
                {LocalStorage.UserTypeId === UserType.TrainingAdmin && (
                  <Fragment>
                    <li className="nav-item">
                      <a href="#" className="nav-link">
                        <Icon
                          icon="healthicons:i-training-class"
                          className="nav-icon "
                        />
                        <p>Training</p>
                        <i className="right fas fa-angle-left"></i>
                      </a>
                      <ul className="nav nav-treeview">
                        <li className="nav-item">
                          <a href="/trainingslots" className="nav-link">
                            <Icon icon="codicon:circle" className="nav-icon" />
                            <p>Training Slots</p>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a href="/trainingschedule" className="nav-link">
                            <Icon icon="codicon:circle" className="nav-icon" />
                            <p>Training Schedule</p>
                          </a>
                        </li>
                      </ul>
                    </li>
                  </Fragment>
                )} */}
                <li className="nav-item">
                  <a
                    href="pages/widgets.html"
                    className="nav-link"
                    onClick={(e) => this.handleOnLogout(e)}
                  >
                    <i className="nav-icon fas fa-sign-out-alt"></i>
                    <p>Logout</p>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </aside>
        {this.state.isLoading && <LogoutLoader />}
      </Fragment>
    );      
  }
}